.select2-container--default {
  .select2-results__option {
    font-size: 1.6rem;
    line-height: 1.2;
    border-top: 0.1rem solid map-deep-get($select2, option, itemBorder);

    &--highlighted {
      &[aria-selected] {
        background: map-deep-get($select2, option, highlitedBg);
      }
    }
  }
}
