$btn: (
  fontSize: 1.6rem,
  fontWeight: 400,

  extraSmall: (
    fontSize: 1.4rem
  ),

  small: (
    fontSize: 1.4rem
  )
);

$btn-default-color:                 map-get($colors, black100);
$btn-default-bg:                    map-get($colors, gray400);
$btn-default-border:                map-get($colors, gray400);

$btn-primary-color:                 map-get($colors, gray800);
$btn-primary-bg:                    map-get($colors, gray700);
$btn-primary-border:                map-get($colors, gray700);

$btn-info-color:                    map-get($colors, black100);
$btn-info-bg:                       map-get($colors, orange100);
$btn-info-border:                   map-get($colors, orange100);

$btn-danger-color:                 map-get($colors, white);
$btn-danger-bg:                    map-get($colors, red100);
$btn-danger-border:                map-get($colors, red100);

$btn-warning-color:                 map-get($colors, black100);
$btn-warning-bg:                    map-get($colors, yellow200);
$btn-warning-border:                map-get($colors, yellow200);

$btn-success-color:                 map-get($colors, white);
$btn-success-bg:                    map-get($colors, green100);
$btn-success-border:                map-get($colors, green100);

$btn-light-color:                 map-get($colors, black100);
$btn-light-bg:                    map-get($colors, white);
$btn-light-border:                map-get($colors, gray1400);
