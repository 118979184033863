&__field {
  display: inline-flex;
  align-items: center;
  margin: 0 1.5rem 0 0;

  .book__footer & {
    display: flex;
    margin: 0;
    width: 100%;
  }

  .material-icons {
    margin: 0 1rem 0 0;
    color: map-deep-get($book, field, iconColor);
  }

  .field-input {
    font-weight: 400;
  }

  &--bold {
    font-weight: 700;

    .field-input {
      font-weight: 700;
    }
  }

  .field-sm {
    .field-static,
    .row-fields {
      padding: 0.3rem 0 0;
    }
  }

  .field-inner {
    position: relative;
    min-width: 20rem;

    .timer & {
      min-width: 5rem;
    }
  }

  .field-flex {
    display: flex;
  }

  .book__btn {
    @include v-align;
    @include fullContain(absolute);
    left: auto;
    right: 0.4rem;
    width: 2.6rem;
    height: 2.6rem;
    border: none;
    border-radius: 0;
    background: map-deep-get($book, field, btnBg);
    z-index: 2;

    .material-icons {
      font-size: 1.4rem;
    }
  }
}
