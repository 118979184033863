.mCSB_scrollTools .mCSB_draggerRail {
    position: relative;
    width: 0.8rem;
    z-index: 2;
    padding-bottom: 1rem;
    background: #f3f3f3;
    border: 0.1rem solid #e8e8e8;
    border-radius: 0.4rem;
}

.mCSB_scrollTools .mCSB_dragger {
    z-index: 10;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
    background: linear-gradient(to right, #d1d1d1 0%, #ececec 100%);
    border-radius: 0.4rem;
    border: 0.1rem solid #d5d5d5;
    width: 0.8rem;
}

.mCSB_scrollTools {
    opacity: 1;
}

.mCSB_inside > .mCSB_container {
    margin-right: 1.5rem;
}
