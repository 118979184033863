&__summary {
  padding: 1.5rem 1.5rem 0 1.5rem;

  .form-group {
    @extend %clearfix;
    margin: 0 0 1rem;
  }

  hr {
    margin: 8px 0;
  }
}
