.close {
  @include animate (background);
  @extend .icon-close;
  font-size: 0;
  margin: -2.2rem -3rem 0 0;

  &:before {
    @extend [class^="icon-"];
    font-size: 2.5rem;
    margin-top: -0.001em;
  }

  &:after,
  [aria-hidden="true"] {
    display: none;
  }

  &:hover {
    background: map-deep-get($modal, close, hoverColor);
  }
}
