$schedule: (
  radius: 0.8rem,
  heading: (
    background: map-get($colors, gray400)
  ),

  item : (
    background: #17161a
  ),

  btn : (
    color: map-get($colors, white),

    holiday: (
      background: #ef6c00,
      borderColor: #a74b00
    ),

    standart: (
      background: #158c51,
      borderColor: #0f6239
    ),

    special :(
      background: #9c27b0,
      borderColor: #6d1b7b
    ),

    premium: (
      background: #0a5596,
      borderColor: #073b69
    ),

    empty: (
      background: transparent,
      borderColor: #585759,
      color: #585759,
      activeBg: #585759,
      activeColor: #2f2e34
    )
  ),

  price: (
    color: #313131
  ),

  edit: (
    background: #A5A3A7,
    activeBg: #444345,
    colorDefault: #FCFCFC,
    activeDefaultColor: #F9F9F9,
    color: #7d7c80
  ),

  footer: (
    background: #e3e1e6,
    btnBg: #a5a3a7,
    btnColor: map-get($colors, white)
  ),

  type: (
    color: map-get($colors, gray200)
  ),

  color: (
    standart: #158c51,
    holiday: #ef6c00,
    special: #9c27b0,
    premium: #065396
  ),

  close :(
    color: #E3E1E6,
    hoverColor: #C03C29
  )
);
