$modal: (
  shadowColor: rgba(105, 117, 140, 0.75),

  close: (
    hoverColor: #75808F
  ),

  title: (
    fontSize: 2rem,
    fontWeight: 700,
    color: map-get($colors, black100)
  )
);
