&--multiple {
  @import 'select2-selection__rendered';
  height: auto;
  padding: 0.6rem 0.5rem 0 0.5rem;

  &.btn-secondary {
    @extend .btn-secondary;
    padding-bottom: 0;
  }

  .select2-selection__choice__remove {
    @include v-align;
    @extend .icon-close;
    font-size: 0;

    &:before {
      @extend [class^="icon-"];
      font-size: 1.2rem;
    }
  }
}
