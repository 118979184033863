.bootstrap-select {
  position: relative;

  [class^="col-"] & {
    width: 100% !important;
  }

  .dropdown-menu {
    margin-top: -0.1rem;

    > li {
      > a {
        > span.text {
          @include text-overflow;
          width: 100%;
        }
      }
    }
  }

  .btn {
    @extend .btn;
    position: relative;
  }

  .btn-sm {
    @extend .btn-sm;
  }

  &.btn-group .dropdown-toggle .caret {
    margin: 0;
  }

  .city {
    font-weight: 700;
  }

  .dropdown-toggle:focus {
    outline: none !important;
  }

  &.btn-group {
    .dropdown-toggle {
      .filter-option {
        display: inline-flex;
        align-items: center;

        .schedule__color {
          margin-right: 1rem;
        }
      }
    }
  }
}
