&__column {
    position: relative;
    flex: 1;
    padding: 0 0.5rem;
    min-height: 0.1rem;

    &:not( .user-board__column--edit, .user-board__column--cancel, .user-board__column--reply, .user-board__column--remove, .user-board__column--card ) {
        word-break: break-all;
    }

    .user-board__header & {
        padding: 0 0.5rem;
    }

    &--remove {
        @include v-align;
        flex: 0 0 4.2rem;
        
        @include media ('>=desktop') {
          position: absolute;
          top: 0;
          bottom: 0;
          left: -3rem;
          background: map-deep-get($userBoard, body, activeRowBg);
          border-radius: 50% 0 0 50%;
          flex: 0 0 auto;
        }
    }

    &--number {
        text-align: center;
        flex: 0 0 3.5rem;
    }

    &--name {
        flex: 0 0 14rem;
    }

    &--lastname,
    &--phone {
        flex: 0 0 15rem;
    }

    &--email {
        flex: 0 0 23.5rem;
    }

    &--card {
        flex: 0 0 8rem;
    }

    &--cancel,
    &--delete,
    &--edit {
        flex: 0 0 4rem;
        padding: 0;
        margin-left: 0.2rem;
    }

    &--reply {
        padding: 0 0.5rem;
    }

    .has-error {
        .help-block {
            opacity: 1;
            visibility: visible;
        }
    }
}
