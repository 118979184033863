.file-preview {
  @extend .file;
  width: 100%;

  &__img {
    max-width: 20rem;
    max-height: 9rem;
    object-fit: contain;
  }

  &__close {
    @include animate (color);
    position: absolute;
    top: -1rem;
    right: -1rem;
    font-size: 2.2rem;
    color: #e3e1e6;
    z-index: 2;

    &:hover {
      color: #bd3b2e;
    }

    &:before {
      content: '';
      width: 1.4rem;
      height: 1.4rem;
      background: map-get($colors, white);
      position: absolute;
      top: 0.4rem;
      left: 0.4rem;
      z-index: -1;
    }
  }
}
