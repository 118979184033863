&__footer {
  background: map-deep-get($book, footer, background);
  padding: 0.8rem 1.8rem 0.6rem 1.2rem;
  border-radius: 0 0 0.5rem 0.5rem;

  .form-group {
    &:last-child {
      margin-bottom: 0;
    }
  }

  .field-wrapper {
    position: static;
    display: block;
  }

  .row-fields {
    width: 100%;
    height: auto;
  }

  textarea {
    resize: none;
  }
}
