&__body {
  @extend %clearfix;
  padding: 1rem;
  @include media ('>=desktop') {
    padding: 2rem 2rem 1rem 2.8rem;
  }

  .form-group {
    margin: 0 0 0.3rem;
  }

  .book__date {
    @include media ('>=desktop') {
      margin: 1.5rem 0 0 6rem;
    }
  }

  .timer {
    margin: 1.5rem auto;
    justify-content: center;
    @include media ('>=desktop') {
      margin: 1.5rem 0 0 6rem;
      justify-content: flex-start;
    }
  }
}
