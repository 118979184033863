&-body {
  line-height: 1.428;
  padding: 1.5rem;
  
  @include media ('>=desktop') {
    padding: 2rem 4rem 0;
  }

  .form-group {
    margin-bottom: 2rem;
  }
}
