&__edit {
  @include animate (color background);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
  background: map-deep-get($schedule, edit, background);
  color: map-deep-get($schedule, edit, colorDefault);
  margin: 0 0 0 0.8rem;
  border-radius: 0.3rem;

  &,
  &:focus {
    background: map-deep-get($schedule, edit, background);
    color: map-deep-get($schedule, edit, colorDefault);
  }

  &:hover,
  &:focus:hover {
    background: map-deep-get($schedule, edit, activeBg);
    color: map-deep-get($schedule, edit, activeDefaultColor);
  }

  .schedule__item & {
    @include animate (opacity);
    font-size: 1.8rem;
    margin-left: 0.5rem;
    opacity: 0;
    visibility: hidden;
    color: map-deep-get($schedule, edit, color);
    background: none;
  }
}
