.help-block {
  position: absolute;
  margin: 0;
  background: map-deep-get($helpBlock, background);
  color: map-deep-get($helpBlock, color);
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  min-width: 20rem;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  margin: 1rem 0 0;

  .has-error & {
    opacity: 1;
    visibility: visible;
    color: map-deep-get($helpBlock, color);
  }

  &:after {
    position: absolute;
    bottom: 100%;
    left: 2rem;
    content: '';
    height: 0;
    width: 0;
    border-left: 0.6rem solid transparent;
    border-right: 0.6rem solid transparent;
    border-bottom: 0.6rem solid map-deep-get($helpBlock, background);
  }
}
