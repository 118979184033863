&__delete {
    @include v-align;
    display: block;
    width: 100%;
    height: 4rem;
    opacity: 0;
    visibility: hidden;

    .user-board__row--edit & {
        opacity: 1;
        visibility: visible;
    }

    &,
    &:active,
    &:focus,
    &:hover {
        background: map-deep-get($userBoard, body, editBg);
        color: map-deep-get($userBoard, body, editColor);
    }

    &--active {
        background: map-deep-get($userBoard, body, editBg);

        &:active,
        &:focus,
        &:hover {
            background: map-deep-get($userBoard, body, activeDeleteBg);
            color: map-deep-get($userBoard, body, activeDeleteColor);
        }
    }
}
