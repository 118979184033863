@mixin btnVariant($bg, $border-color, $color, $tone, $hoverTrue) {
  background: $bg;
  border: 1px solid $border-color;
  color: $color;

  @if ($hoverTrue == true) {
    &:hover {
      color: $color;

      @if ($tone == light) {
        background: lighten($bg, 10%);
        border-color: lighten($bg, 10%);
      } @else if ($tone == dark) {
        background: darken($bg, 2%);
        border-color: darken($bg, 2%);
      }
    }
  } @else if ($hoverTrue == false) {
    &:hover {
      background: $bg;
      border: 1px solid $border-color;
      color: $color;
    }
  }
}
