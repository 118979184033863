.main {
  @extend %center-block;
  max-width: 95rem;
}

.container-sm {
  @extend %center-block;
  max-width: 96rem;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.center-block {
  float: none;
  margin-left: auto;
  margin-right: auto;
}

.content {
  padding: 1.5rem 0;
  @include media ('>desktop') {
    padding: 3rem 0 1rem;
  }
}

.spacer {
  display: inline-block;
  width: 1.6rem;
  height: 0.2rem;
  background: map-get($colors, gray1000);
  text-indent: -999px;
}
