&-footer {
  text-align: center;
  padding: 1.5rem;

  @include media ('>=desktop') {
    display: flex;
    align-items: center;
    padding: 1.3rem 4rem 2.6rem;
    text-align: left;
  }

  .btn-link {
    padding: 0;
    text-decoration: underline;
  }

  .btn + .btn {
    @include media ('>=desktop') {
      margin-left: 3rem;
    }
  }
}
