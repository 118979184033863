&__btn {
  @include v-align;
  font-size: 1.2rem;
  line-height: 1.5rem;
  border-radius: 0.3rem;
  text-align: center;
  height: 4rem;
  color: map-deep-get($schedule, btn, color);
  padding: 0.5rem 0;
  width: 10rem;
  flex: 0 0 10rem;
  border-bottom: 0.2rem solid transparent;

  &--holiday {
    background: map-deep-get($schedule, btn, holiday, background);
    border-bottom-color: map-deep-get($schedule, btn, holiday, borderColor);
  }

  &--standart {
    background: map-deep-get($schedule, btn, standart, background);
    border-bottom-color: map-deep-get($schedule, btn, standart, borderColor);
  }

  &--special {
    background: map-deep-get($schedule, btn, special, background);
    border-bottom-color: map-deep-get($schedule, btn, special, borderColor);
  }

  &--premium {
    background: map-deep-get($schedule, btn, premium, background);
    border-bottom-color: map-deep-get($schedule, btn, premium, borderColor);
  }

  &--empty {
    background: map-deep-get($schedule, btn, empty, background);
    border: 0.2rem solid map-deep-get($schedule, btn, empty, borderColor);
    color: map-deep-get($schedule, btn, empty, color);
    font-size: 2.6rem;

    @supports (display: flex) {
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    &:hover {
      background: map-deep-get($schedule, btn, empty, activeBg);
      color: map-deep-get($schedule, btn, empty, activeColor);
    }
  }
}
