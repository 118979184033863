.close.close {
  @include v-align;
  display: block;
  width: 3rem;
  height: 3rem;
  color: map-deep-get($close, color);
  opacity: 1;
  border-radius: 50%;
  background: map-deep-get($close, background);
  font-size: 0;
  @supports (display: flex) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &:after {
    @extend %material-icons;
    content: 'close';
    display: inline-block;
    width: 2.4rem;
  }
}
