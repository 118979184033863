&__status {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;

  .dropdown {
    min-width: 19rem;
    margin: 0 0 0 2.1rem;

    .bootstrap-select {
      width: 100% !important;
    }
  }
}
