.file {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #f3f3fa;
  border: 0.1rem solid #d8dce6;
  cursor: pointer;
  margin: 0 0 3rem;
  height: 9.8rem;

  &:hover {
    background: #f0faff;
  }

  input[type="file"] {
    position: absolute;
    opacity: 0;
    visibility: hidden;
  }

  &__img {
    display: block;
    margin-bottom: 1.5rem;
    width: 4.6rem;
    height: 3.6rem;
    background: url('../images/image-list-bg.png') no-repeat;

    .file.active &,
    .file:hover & {
      background: url('../images/image-list-bg-h.png') no-repeat;
    }
  }

  &__text {
    color: #adb0b7;

    .file.active &,
    .file:hover & {
      color: #8ea5bb;
    }
  }

  .file-preview {
    margin: 0;
  }
}
