&__item {
  @include animate (background);
  display: flex;
  align-items: center;
  padding: 1rem 0 1rem 1rem;
  margin: 0 -3rem -1rem -1rem;
  border-radius: 1rem;
  cursor: pointer;

  &:not(.schedule__item--empty) {
    &.active,
    &:hover {
      background: map-deep-get($schedule, item, background);

      .schedule__edit {
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
