.book-list {
    padding: 3rem 0;
}

.book {
    @extend %clearfix;
    background: map-get($book, background);
    border-radius: map-get($book, borderRadius);
    margin-bottom: 3rem;
    /*
    * Import childrens
    */
    @import 'book__heading';
    @import 'book__date';
    @import 'book__name';
    @import 'book__title';
    @import 'book__location';
    @import 'book__status';
    @import 'book__body';
    @import 'book__summary';
    @import 'book__field';
    @import 'book__footer';
    @import 'book__comment';

    .row-fields {
        position: relative;
    }
}
