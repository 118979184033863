&__column {
  flex: 0 0 10rem;
  max-width: 10rem;
  margin-right: 3.7rem;
  text-align: center;

  &:last-child {
    margin-right: 0;
  }
}
