.filter {
  position: relative;
  @include media ('>desktop') {
    padding: 4.5rem 0.8rem 0.8rem;
  }

  [class^="col-"] {
    @include media ('>desktop') {
      padding: 0 0.8rem;
    }
  }

  .bootstrap-select.bootstrap-select.bootstrap-select {
    width: 100%;
    position: relative;

    .dropdown-toggle {
      @extend .btn-light.dropdown-toggle;
      overflow: hidden;
      position: relative;
    }

    .dropdown-menu {
      margin: 0;

      li {
        a {
          font-size: 1.6rem;
          position: relative;
          display: block;
          padding: 0.4rem 1.4rem;
          text-decoration: none;
          color: #001133;
          border-top: 0.1rem solid #f0f3f8;
          overflow: hidden;
          text-align: left;

          &.text--bold {
            @extend .text--bold;
          }
        }
      }
    }
  }

  .control-label {
    display: block;
    min-height: 1.5rem;
  }

  a:not(.datepicker-clear, .export-doc) {
    @include v-align;
    @include inheritTypography;

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  .export-doc {
    @include v-align;
    @include inheritTypography;
    font-size: 1.2rem;
    text-align: left;

    @include media ('>=desktop') {
      font-size: 1.4rem;
    }

    &:active,
    &:focus,
    &:hover {
      text-decoration: none;
    }

    img {
      max-width: 2rem;

      @include media ('>=desktop') {
        max-width: none;
      }
    }
  }
}
