.dropdown-toggle {
  &,
  &:active,
  &:active:focus,
  &:focus {
    outline: none;
  }

  .caret {
    position: absolute;
    top: 50%;
    right: 1.1rem;
    transform: translateY(-50%);
  }
}

.dropdown-menu {
  padding: 0;
  margin: 0;
  border: 1px solid #ccc;
  border-radius: 0;
  box-shadow: none;

  > li {
    > a {
      font-size: 1.6rem;
      position: relative;
      display: block;
      padding: 0.65rem 1.5rem;
      margin: -0.1rem 0 0.1rem;
      text-decoration: none;
      color: #001133;
      border-bottom: 0.1rem solid #f0f3f8;
      overflow: hidden;
    }
  }

  &:last-child {
    > a {
      border-bottom: none;
      margin: 0;
    }
  }
}
