$timer: (
  icon: (
    fontSize: 3.5rem,
    background: map-get($colors, white),
    successBorderColor: map-get($colors, green),
    successColor: map-get($colors, green)
  ),

  timeCounter: (
    fontSize: 2.4rem,
    lineHeight: 1,
    fontWeight: 700,
    successColor: map-get($colors, green),
    errorColor: map-get($colors, red)
  ),

  time: (
    fontSize: 1.6rem,
    fontWeight: 700,
    color: map-get($colors, black100),
    colorSuccess: map-get($colors, green)
  ),

  label: (
    color: map-get($colors, black100)
  )
)
