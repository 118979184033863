&-link {
  background: none;
  border: none;
  outline: none;
  color: map-get($colors, blue100);


  &:hover {
    text-decoration: none;
  }

  &.text-default {
    @extend .text-default;
  }
}
