$book: (
    background: map-get($colors, gray100),
    borderRadius: map-get($radius, default),

    heading: (
      background: map-get($colors, gray400)
    ),

    date: (
      fontSize: 1.3rem
    ),

    title: (
      fontSize: 1.6rem,
      lineHeight: 2.5rem,
      fontWeight: 700,
      color: map-get($colors, black100)
    ),

    location: (
      fontSize: 1.3rem,
      color: map-get($colors, gray200)
    ),

    field: (
      iconColor: map-get($colors, gray300),
      btnBg: map-get($colors, gray400)
    ),

    footer: (
      background: map-get($colors, gray700)
    ),

    comment: (
      borderColor: map-get($colors, gray1400)
    )
);
