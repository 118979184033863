.btn {
  @include v-align;
  display: inline-block;
  font-size: map-deep-get($btn, fontSize);
  font-weight: map-deep-get($btn, fontWeight);
  outline: none;
  border-radius: 0.4rem;
  padding: 1rem;
  text-align: center;
  height: 4rem;

  &-block {
    display: block;
    width: 100%;
  }

  &-default {
    @include btnVariant($btn-default-bg, $btn-default-border, $btn-default-color, dark, true);
  }

  &-success {
    @include btnVariant($btn-success-bg, $btn-success-border, $btn-success-color, light, true);
  }

  &-danger {
    @include btnVariant($btn-danger-bg, $btn-danger-border, $btn-danger-color, light, true);
  }

  &-warning {
    @include btnVariant($btn-warning-bg, $btn-warning-border, $btn-warning-color, light, true);
  }

  &-light {
    @include btnVariant($btn-light-bg, $btn-light-border, $btn-light-color, dark, true);

    &.dropdown-toggle {
      @include btnVariant($btn-light-bg, $btn-light-border, $btn-light-color, dark, false);
    }
  }

  &-primary {
    @include btnVariant($btn-primary-bg, $btn-primary-border, $btn-primary-color, dark, true);
  }


  &.text-left {
    text-align: left;
  }

  &.text-bold {
    @extend .text-bold;
  }

  &.text-default {
    @extend .text-default;
  }

  //Size gradation
  &-lg {
    min-width: 21rem;

    &.btn-block {
      min-width: unset;
    }
  }


  @import 'btn-link';
  @import 'btn-xs';
  @import 'btn-sm';
  @import 'btn-disabled';
  @import 'btn-secondary';
}
