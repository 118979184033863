.lang-tabs {
  position: relative;
  display: flex;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 0.1rem;
    background: map-get($colors, gray2000);
  }

  > li {
    flex: 1;
    max-width: 16rem;

    & + li {
      margin: 0 0.3rem 0 0;
    }

    &.active {
      z-index: 1;

      > a,
      > a:focus {
        background: map-get($colors, white);
        border-color: map-get($colors, gray2000);
        color: map-get($colors, black100);
      }
    }

    > a {
      display: inline-block;
      width: 100%;
      padding: 1rem 1.5rem;
      text-align: center;
      background: map-get($colors, white);
      color: map-get($colors, blue100);
      border: 0.1rem solid transparent;
      border-bottom: 0;
      border-radius: 0.4rem 0.4rem 0 0;

      &:hover {
        background: map-get($colors, gray2000);
        border-color: map-get($colors, gray2000);
        color: map-get($colors, black100);
      }
    }
  }
}
