&__footer {
  @extend %clearfix;
  background: map-deep-get($schedule, footer, background);
  padding: 1.5rem 2rem;
  border-radius: 0 0 map-get($schedule, radius) map-get($schedule, radius);

  .form-inline {
    @extend %clearfix;
    position: relative;

    .btn,
    .form-control {
      padding: 0.5rem 1rem;
      height: 3.2rem;
    }

    .form-control {
      padding-right: 20rem;
      width: 100%;
    }

    .btn {
      font-size: map-get($base, base-font-size);
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      font-weight: 700;
      background: map-deep-get($schedule, footer, btnBg);
      color: map-deep-get($schedule, footer, btnColor);
      min-width: 19rem;
      border-radius: 0 0.3rem 0.3rem 0;
      border: none;
    }
  }
}
