&__body {
  @extend %clearfix;
  display: flex;
  background: url('../images/bg-tabs.jpg');
  padding: 2rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @include media ('>=desktop') {
      overflow: hidden;
  }
}
