&--single {
    @import 'select2-selection__rendered';
    @import 'select2-selection__arrow';
    height: auto;

    &.btn{
      @extend .btn;
    }

    &.btn-sm {
      @extend .btn-sm;
    }

    &.btn-secondary {
        @extend .btn-secondary;
    }

    .select2-selection__placeholder {
      @include text-overflow;
    }
}
