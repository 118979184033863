.select2-container {
  &--focus,
  &:active,
  &:focus {
    outline: none;
  }

  @import 'select2-search--inline';

  .select2 {
    &-selection {
      @import 'select2-selection--single';
      @import 'select2-selection--multiple';
    }
  }

  &--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent map-deep-get($select2, arrow, color);
    border-width: 0 0.4rem 0.4rem;
  }
}
@import 'select2-results-option';
