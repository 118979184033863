// .mt-0 {
//   margin-top: 0 !important;
// }
//
// .ml-1 {
//   margin-left: ($spacer * .25) !important;
// }
//
// .px-2 {
//   padding-left: ($spacer * .5) !important;
//   padding-right: ($spacer * .5) !important;
// }
//
// .p-3 {
//   padding: $spacer !important;
// }
//
$spacers: (
  0: 0,
  1: 0.25,
  2: 0.5,
  3: 1,
  4: 1.3,
  5: 3
);

$spacer: 3rem;

@each $child, $spacerItem in $spacers {
  .mb-#{$child} {
    @extend %clearfix;
    margin-bottom: $spacerItem * $spacer;
  }

  .ml-#{$child} {
    @extend %clearfix;
    margin-left: $spacerItem * $spacer;
  }

  .mr-#{$child} {
    @extend %clearfix;
    margin-right: $spacerItem * $spacer;
  }
}
