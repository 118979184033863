.text {
  &:active,
  &:focus,
  &:hover {
    text-decoration: none;
    outline: none;
    color: inherit;
  }

  &--muted {
    color: map-deep-get($text, muted, color);
  }

  &--dark {
    color: map-deep-get($text, dark, color);

    &:hover {
      color: map-deep-get($text, dark, color);
    }
  }

  &--dashed {
    border-bottom: 2px dotted;
  }

  &--dashed-gray {
    border-color: map-get($text, dashedGray);
  }

  &--small {
    font-size: map-deep-get($text, small, fontSize);
  }

  &--medium {
    font-size: map-deep-get($text, medium, fontSize);
  }

  &--bold {
    font-weight: 700;
  }
}
