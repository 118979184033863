$userBoard: (
  header: (
    fontSize: 1.4rem,
    fontWeight: 700,
    color: map-get($colors, gray500)
  ),

  body: (
    fontSize: 1.4rem,
    color: map-get($colors, black100),
    activeRowBg: map-get($colors, gray400),
    editBg: map-get($colors, gray600),
    editColor: map-get($colors, black100),
    replyColor: map-get($colors, gray500),
    addColor: map-get($colors, blue100),
    deleteColor: map-get($colors, red),
    activeEditBg: map-get($colors, green),
    activeEditColor: map-get($colors, white),
    activeDeleteBg: map-get($colors, red),
    activeDeleteColor: map-get($colors, white),
    removeColor: darken(map-get($colors, gray600), 20%),
    removeActiveColor: map-get($colors, red),
    helpBlockBg: rgba(map-get($colors, black100), 0.8),
    helpBlockColor: map-get($colors, white),
    rowBorderedColor: map-get($colors, gray400)
  )
);
