.form-control {
  & + .spinedit {
    width: 2rem;
    font-size: 2rem;
    z-index: 2;
    display: flex;
    flex-direction: column;
    float: right;
    margin-top: -3rem;

    .icon-chevron-up,
    .icon-chevron-down {
      display: flex;
      align-items: center;
      height: 1.6rem;
      overflow: hidden;
      cursor: pointer;
    }

    .icon-chevron-up {
      @extend .icon-arrow_drop_up;
    }

    .icon-chevron-down {
      @extend .icon-arrow_drop_down;
    }
  }

  &-sm {
    & + .spinedit {
      margin-top: -3.2rem;
      height: 3.2rem;
      padding: 0.5rem 0;
    }
  }
}
