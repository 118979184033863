&__color {
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  float: left;
  margin-right: 0.7rem;

  &--standart {
    background: map-deep-get($schedule, color, standart);
  }

  &--special {
    background: map-deep-get($schedule, color, special);
  }

  &--holiday {
    background: map-deep-get($schedule, color, holiday);
  }

  &--premium {
    background: map-deep-get($schedule, color, premium);
  }
}
