.schedule {
  position: relative;
  @import 'schedule__heading';
  @import 'schedule__body';
  @import 'schedule__column';
  @import 'schedule__list';
  @import 'schedule__item';
  @import 'schedule__btn';
  @import 'schedule__price';
  @import 'schedule__edit';
  @import 'schedule__footer';
  @import 'schedule__type';
  @import 'schedule__color';
  @import 'schedule__close';
}
