// This is base reset for global components.
//
// Note: If you use a framework that includes base styling then you need to remove this file.

html {
	box-sizing: border-box;
	height: 100%;
	font-size: 62.5%;
}

*,
*:after,
*:before {
	box-sizing: inherit;
}

body {
	// @include font-smoothing(on);
	display: table;
	width: 100%;
	height: 100%;
	margin: 0 auto;
	background: map-get($base, 'base-background-color');
	table-layout: fixed;
}
// adaptive images

img {
	max-width: 100%;
	height: auto;
}
// google map fix

.gm-style img {
	max-width: none;
}
// hide tel links on desktop
@media only screen and (min-width : 1025px) {
	a[href^="skype"],
	a[href^="sms"],
	a[href^="tel"] {
		pointer-events: none;
		cursor: default;
	}
}

a,
button {
	&,
	&:active,
	&:focus,
	&:hover,
	&:visited,
	&:hover:focus,
	&:active:focus,
	.bootstrap-select .dropdown-toggle {
		outline: none !important;
		box-shadow: none;
		text-decoration: none;
	}
}
