&__title {
  font-size: map-deep-get($book, title, fontSize);
  line-height: map-deep-get($book, title, lineHeight);
  font-weight: map-deep-get($book, title, fontWeight);
  color: map-deep-get($book, title, color);
  margin: 0;

  a {
    &,
    &:active,
    &:focus,
    &:hover {
      @include inheritTypography;
      text-decoration: none;
    }
  }
}
