$text: (
  dashedGray: #b4b3b5,
  
  muted: (
    color: map-get($colors, gray300)
  ),

  small: (
    fontSize: 1.3rem
  ),

  medium: (
    fontSize: 1.6rem
  ),

  dark: (
    color: map-get($colors, black100)
  )
)
