.table {
  > thead {
    > tr {
       > td,
      > th {
        font-size: 1.3rem;
        font-weight: 400;
        color: map-deep-get($table, thead, color);
        padding: 2.1rem 1.5rem 0;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        a {
          color: inherit;
        }
      }
    }
  }

  > tbody {
    > tr {
       > td,
      > th {
        border-bottom-color: map-get($table, borderColor);
        padding: 2.1rem 1.5rem;

        &:first-child {
          padding-left: 0;
        }

        &:last-child {
          padding-right: 0;
        }

        a {
          color: map-get($table, linkColor);
          border-bottom: 0.1rem solid map-get($table, linkBorder);
          font-weight: 700;
        }
      }
    }
  }
}
