.form-control {
  color: map-deep-get($formControl, color);
  @include placeholder() {
    color: map-get($formControl, placeholderColor);
  }

  &,
  &:active,
  &:focus,
  &:hover {
    box-shadow: none;
    outline: none;
  }

  &--md {
    padding: 0.65rem 1rem;
  }

  &--rounded {
    border-radius: 0.4rem;
  }

  .timer__time--edit & {
    font-size: 2.4rem;
    color: #161618;
    padding: 0.3rem 1.2rem;
    width: 5.3rem;
    border: 0.1rem solid #009bd4;
    box-shadow: 0 0 0.5rem 0.1rem rgba(0, 111, 180, 0.5);
    border-radius: 0.2rem;
    position: relative;
    z-index: 2;
  }

  @import 'form-control-sm'
}

textarea.form-control-sm {
  min-height: 3.2rem;
}

.control-label {
  font-weight: map-get($controlLabel, fontWeight);
  color: map-get($controlLabel, color);

  &.text-muted{
    @extend .text-muted;
  }
}

.wrapper-input-field input {
  font-size: 24px;
  color: #161618;
  padding: 3px 12px;
  width: 50px;
  outline: none;
  border: 0.1rem solid #009bd4;
  box-shadow: 0 0 0.5rem 0.1rem rgba(0, 111, 180, 0.5);
}

.wrapper-input-field input:active,
.wrapper-input-field input:focus {
  outline: none;
  border: 0.1rem solid #009bd4;
  box-shadow: 0 0 0.5rem 0.1rem rgba(0, 111, 180, 0.5);
}

input[type='date'].form-control {
  line-height: 1.42857;
}
