// This is base reset for form elements.
//
// Note: If you use a framework that includes form styling then you need to remove this file.

fieldset,
form {
    margin: 0;
    padding: 0;
    border-style: none;
}

input[type='email'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
textarea {
    -webkit-appearance: none;
    box-sizing: border-box;
}

select {
    -webkit-border-radius: 0;
    height: 3.2rem;
    width: 100%;
    background: transparent;
    border: 0.1rem solid #ccc;
}

.select-default-sm {
  height: 2.8rem;
}

textarea {
    resize: vertical;
    vertical-align: top;
}

button,
input[type='button'],
input[type='file'],
input[type='reset'],
input[type='submit'] {
    -webkit-appearance: none;
    cursor: pointer;
}
