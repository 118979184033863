.dropdown-light {
  &-lg {
    > .dropdown-toggle {
      strong {
        margin-bottom: 5px;
      }
    }
  }
  > .dropdown-toggle {
    line-height: 16px;
    background: #fff;
    border: 1px solid #ccc;
    overflow: hidden;
    padding: 13px 14px;
    position: relative;
    border-radius: 4px;
    font-size: 13px;
    color: #161618;
    text-align: left;
    height: auto;

    .small {
      display: block;
      width: 100%;
      flex: 0 0 100%;
      font-size: 13px;
      color: #666666;
    }

    .caret {
      position: absolute;
      color: #000;
      top: 50%;
      transform: translateY(-50%);
      right: 12px;

      .dropdown-light-lg & {
        top: 18px;
      }
    }

    strong {
      display: block;
      width: 100%;
      flex: 0 0 100%;
      font-size: 16px;
      line-height: 1;
      color: #161618;
      font-weight: normal;
    }

    .filter-option {
      flex-wrap: wrap;
    }
  }

  .dropdown-menu {
    width: 100%;
    border: 1px solid #ccc;
    border-top: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
    border-radius: 0;

    > li {
      > a {
        font-size: 16px;
        position: relative;
        display: block;
        padding: 6.5px 14px;
        text-decoration: none;
        border-bottom: 1px solid #f0f3f8;
        overflow: hidden;
        color: #161618;

        &:hover {
          background: #dddddd;
          color: #161618;
        }

        .small {
          font-size: 13px;
          color: #666;
        }

        strong {
          display: block;
          width: 100%;
          font-size: 16px;
          line-height: 1;
          font-weight: normal;
        }
      }

      &:last-child {
        > a {
          border-bottom: none;
          margin: 0;
        }
      }
    }
  }
}
