&-dialog {
    /*
    * Modal dialog vertical center realization
    */
    @include media ('>=desktop') {
      display: inline-block;
      text-align: left;
      vertical-align: middle;
    }
}
