&__heading {
  background: map-deep-get($schedule, heading, background);
  padding: 1rem 2rem;
  border-radius: map-get($schedule, radius) map-get($schedule, radius) 0 0;

  h3 {
    margin: 0;
    line-height: 2rem;
  }

  .dl-horizontal {
    dt {
      @include media ('>=tablet') {
        display: inline-block;
        width: 12rem;
        text-align: left;
      }
    }

    dd {
      @include media ('>=tablet') {
        display: inline-block;
        margin-left: 0;
        width: 15.3rem;
      }
    }
  }
}
