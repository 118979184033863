&__reply {
    display: flex;
    align-items: center;

    &,
    &:active,
    &:focus,
    &:hover {
        color: map-deep-get($userBoard, body, replyColor);
        text-decoration: none;
    }

    .material-icons {
        margin-right: 0.6rem;
    }
}
