&__arrow{
  @include triangle(2.5rem, map-get($colors, white), up);
  position: absolute;
  bottom: 100%;
  left: 2rem;
  opacity: 0;
  visibility: hidden;

  @include media ('>=desktop') {
    opacity: 1;
    visibility: visible;
  }
}
