////
// Nested list cycle for text sizes;
////
$sizes: ( xs: 1rem, sm: 1.3rem, lg: 1.6rem, xl: 1.8rem);
@each $child, $size in $sizes {
  .text-#{$child} {
    font-size: $size;
  }
}

.link {
  display: inline-flex;
  align-items: center;
}

.text-default {
  font-size: map-deep-get($base, base-font-size);
}

.text-dark {
  color: map-get($colors, black100);
}

.text-light {
  color: map-get($colors, white);
}

.text-muted {
  color: map-get($colors, gray300);
}

.text-bold {
  font-weight: 700;
}

.text-underline {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}


.icon {
  &--left {
    margin-right: 0.7rem;
  }
  &--xs {
    font-size: 1rem;
  }
  &--lg {
    font-size: 2rem;
  }
  &--xl {
    font-size: 2.5rem;
  }
}
