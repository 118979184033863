.daterangepicker {
  width: calc(100% - 3rem);
  padding: 0.5rem;
  margin-top: 1.5rem;
  border-color: map-deep-get($datepicker, borderColor);
  border-radius: map-deep-get($datepicker, borderRadius);
  box-shadow: none;
  @include media ('>=tablet') {
    width: calc(100% - 4rem);
  }
  @include media(">=desktop") {
    width: 85rem;
  }

  &:after,
  &:before {
    display: none;
  }

  .input-mini {
    display: none;
  }

  .range_inputs {
    margin-bottom: 1.5rem;

    @include media(">=lg-desktop") {
      margin: 0;
    }
  }

  .ranges {
    @include media ('>=tablet') {
      width: 16.4rem;
    }
    @include media ('>=desktop') {
      width: 27rem;
      margin-right: 1rem;
    }

    ul {
      width: 100%;
    }

    li {
      position: relative;
      font-size: 1.7rem;
      line-height: 1;
      color: map-deep-get($datepicker, range, color);
      padding: 0.4rem;
      background: map-deep-get($datepicker, range, background);
      border-color: map-deep-get($datepicker, range, borderColor);
      margin: 0;

      &:last-child {
        margin-bottom: 1rem;
        @include media(">=lg-desktop") {
          margin: 6.9rem 0 1rem;
        }
      }

      &.active,
      &:hover {
        background: map-deep-get($datepicker, range, activeBackground);
        border-color: map-deep-get($datepicker, range, activeBorderColor);
      }

      &.active {
        &:after {
          @include triangle(1rem, map-get($colors, black), right);
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 0.7rem;
          display: inline-block;
          content: "";
        }
      }
    }
  }

  td {
    &,
    &.end-date,
    &.start-date,
    &.start-date.end-date,
    &.end-date.start-date {
      border-radius: 0;
    }
  }

  .calendar {
    width: 100%;
    max-width: 100%;
    background: map-deep-get($datepicker, calendar, background);
    border: 0.1rem solid map-deep-get($datepicker, calendar, borderColor);
    margin: 0;
    padding: 0.5rem;

    @include media ('>=tablet') {
      max-width: 27.2rem;
    }
    @include media ('>=desktop') {
      max-width: 27.7rem;
    }

    &.left {
      @include media(">=tablet") {
        border-right: none;
        border-radius: map-deep-get($datepicker, borderRadius) 0 0
          map-deep-get($datepicker, borderRadius);
      }
    }

    &.right {
      @include media(">=tablet") {
        border-left: none;
        border-radius: 0 map-deep-get($datepicker, borderRadius)
          map-deep-get($datepicker, borderRadius) 0;
      }
    }
  }
}

.calendar-table {
  .daterangepicker.ltr .calendar.left.left & {
    padding-right: 0.3rem;
  }

  .daterangepicker.ltr .calendar.left &,
  .daterangepicker.ltr .calendar.right & {
    border-radius: 0.4rem;
  }

  table {
    border-spacing: 0.1rem;
    border-collapse: initial;
  }

  .calendar & {
    padding: 0.3rem;

    .table-condensed {
      thead {
        > tr {
          &:first-child {
            > th {
              background: map-deep-get($datepicker, calendar, background);
              border-radius: 0;
              padding: 3px 0;
              border-top: 0.1rem solid
                map-deep-get($datepicker, calendar, borderColor);
              border-bottom: 0.1rem solid
                map-deep-get($datepicker, calendar, borderColor);

              &:first-child {
                border-radius: map-deep-get($datepicker, borderRadius) 0 0
                  map-deep-get($datepicker, borderRadius);
                border-left: 0.1rem solid
                  map-deep-get($datepicker, calendar, borderColor);
              }

              &:last-child {
                border-radius: 0 map-deep-get($datepicker, borderRadius)
                  map-deep-get($datepicker, borderRadius) 0;
                border-right: 0.1rem solid
                  map-deep-get($datepicker, calendar, borderColor);
              }
            }
          }

          &:last-child {
            > th {
              font-size: 1.4rem;
              font-weight: 700;
              padding: 0.9rem 0.4rem;
            }
          }
        }
      }

      .monthselect,
      .yearselect {
        font-size: 15px;
      }

      .off {
        opacity: 0;
        visibility: hidden;
      }

      .available {
        border: 0.1rem solid
          map-deep-get($datepicker, calendarCell, borderColor);
        background: map-deep-get($datepicker, calendarCell, background);
        color: map-deep-get($datepicker, calendarCell, color);
        text-align: right;
        padding: 0.3rem;

        &:hover {
          border-color: map-deep-get($datepicker, calendarCell, hoverBorder);
        }

        &.active {
          border-color: map-deep-get($datepicker, calendarCell, activeBorder);
          background: map-deep-get($datepicker, calendarCell, activeBg);
        }
      }

      .in-range {
        border: 0.1rem solid
          map-deep-get($datepicker, calendarCell, inRangeBorder);
        background: map-deep-get($datepicker, calendarCell, inRangeBg);
      }

      .today {
        border: 0.1rem solid
          map-deep-get($datepicker, calendarCell, todayBorder);
        background: map-deep-get($datepicker, calendarCell, todayBg);
      }

      .next,
      .prev {
        vertical-align: middle;
        text-align: center;

        .fa {
          @include v-align;
          display: inline-block;
          vertical-align: middle;
          width: 2rem;
          height: 2rem;
          background: map-get($colors, black);
          border-radius: 50%;
          @supports (display: flex) {
            display: inline-flex;
            align-items: center;
            justify-content: center;
          }

          &:before {
            display: inline-block;
            content: "";
          }
        }
      }

      .prev {
        .fa {
          &:before {
            @include triangle(1rem, map-get($colors, white), left);
            margin-left: -0.1rem;
          }
        }
      }

      .next {
        .fa {
          &:before {
            @include triangle(1rem, map-get($colors, white), right);
            margin-right: -0.1rem;
          }
        }
      }
    }
  }
}
