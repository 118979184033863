&__row {
  @extend %clearfix;
  @include animate (background);
  position: relative;
  display: flex;
  align-items: center;
  @include media ('>=desktop') {
    margin: 0 -2rem 0 -3rem;
  }

  &--bordered {
    border-bottom: 0.1rem solid map-deep-get($userBoard, body, rowBorderedColor);

    .user-board__column {
      &:first-child {
        padding-left: 0;
      }
    }
  }

  .user-board__body & {
    &--edit,
    &:hover {
      @include media ('>=desktop') {
        background: map-deep-get($userBoard, body, activeRowBg);
      }
    }
  }

  .user-popup & {
    margin: 0;

    &.active,
    &:hover {
      background: none;
      cursor: pointer;
    }
  }
}
