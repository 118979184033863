.timer {
  display: flex;
  align-items: center;

  &-toggle {
      position: fixed;
      top: 100px;
      left: 0;
      z-index: 10;
      border: none;
      background: #2e8838;
      color: #fff;
      padding: 10px;
      line-height: 1;
      border-radius: 0 5px 5px 0;

      @include media ('>=lg-desktop') {
          display: none;
      }
  }

  &--aside {
    display: flex;
    flex-wrap: wrap;
    border-radius: 3px;
    background-clip: padding-box;
    border: 1px solid #e3e1e6;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
    background: #fff;

    &:not(:last-child) {
      margin: 0 0 10px;
    }

    @include media(">=lg-desktop") {
      width: 140px;
    }
  }

  &-container {
    position: fixed;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    overflow: auto;
    background: rgba(#000, 0.6);
    padding: 60px 20px;
    opacity: 0;
    visibility: hidden;

    &.in {
        opacity: 1;
        visibility: visible;
    }

    @include media(">=lg-desktop") {
      left: 0;
      right: auto;
      top: 62px;
      bottom: 62px;
      width: 175px;
      background: none;
      opacity: 1;
      visibility: visible;
      overflow: auto;
    }

    &-close {
        background: none;
        border: none;
        outline: none;
        color: #fff;
        font-size: 35px;
        position: fixed;
        top: 10px !important;
        left: auto !important;
        right: 10px !important;
        bottom: auto !important;
        text-shadow: 0 0 10px #000;
        z-index: 10;

        @include media ('>=lg-desktop') {
            display: none;
        }
    }
  }

  &__icon {
    @include animate(background border-color);
    display: flex;
    width: 5rem;
    flex: 0 0 5rem;
    height: 5rem;
    margin: 0 1rem 0 0;
    border: 0.3rem solid transparent;
    border-radius: 50%;
    align-items: center;
    font-size: map-deep-get($timer, icon, fontSize);
    justify-content: center;
    background: map-deep-get($timer, icon, background);
    cursor: pointer;

    .timer--aside & {
      font-size: 20px;
      flex: 0 0 3rem;
      width: 3rem;
      height: 3rem;
      margin-right: 0.5rem;
    }

    &--success {
      color: map-deep-get($timer, icon, successColor);
      border-color: map-deep-get($timer, icon, successBorderColor);

      .icon-play,
      .icon-stop {
        display: none;
      }
    }

    &--start {
      color: #fff;
      background: #2e8838;
      border-color: #2e8838;

      &:hover {
        background: darken(#2e8838, 10%);
        border-color: darken(#2e8838, 10%);
      }

      .icon-stop,
      .icon-success {
        display: none;
      }
    }

    &--danger {
      color: #6d1c07;
      background: #e03201;
      border-color: #e03201;

      &:hover {
        background: darken(#e03201, 10%);
        border-color: darken(#e03201, 10%);
      }

      .icon-play,
      .icon-success {
        display: none;
      }
    }

    .material-icons {
      font-size: inherit;
    }
  }

  &__heading {
    font-size: 13px;
    line-height: 1.4;
    width: 100%;
    flex: 0 0 100%;
    margin: 0 0 5px;

    time {
      display: block;
      width: 100%;
    }
  }

  &__time {
    display: flex;
    color: map-deep-get($timer, time, color);
    align-items: center;
    min-height: 6.7rem;

    &--success {
      color: map-deep-get($timer, time, colorSuccess);
    }

    &--start {
      color: #2e8838;
    }

    &--danger {
      color: #e03201;
    }

    &--edit {
      display: none;
      transform: translateY(15px);

      .timer-is-edit & {
        display: flex;
      }

      .field-save {
        position: relative;
        display: flex;
        left: 0;
        right: 0;
        top: -1px;
        z-index: 1;
      }

      .field-save__btn {
        width: 2.6rem;
        height: 2.2rem;

        &--save {
          color: map-get($colors, green);

          &:hover {
            background: map-get($colors, green);
            color: map-get($colors, white);
          }
        }

        &--cancel {
          color: map-get($colors, red);

          &:hover {
            background: map-get($colors, red);
            color: map-get($colors, white);
          }
        }
      }

      .material-icons {
        font-size: 1.5rem;
      }

      .timer__label {
        margin: 1.5rem 0 0 0.5rem;
      }
    }

    .timer-is-edit & {
      display: none;
    }

    .timer--aside & {
      min-height: 1rem;
    }
  }

  &__label {
    color: map-deep-get($timer, label, color);
    margin: 0 0 0.2rem;

    &:hover {
      color: map-deep-get($timer, label, color);
    }
  }

  &__counter {
    display: flex;
    align-items: flex-end;
    font-size: map-deep-get($timer, timeCounter, fontSize);
    font-weight: map-deep-get($timer, timeCounter, fontWeight);
    line-height: map-deep-get($timer, timeCounter, lineHeight);
    border-bottom: 0.1rem dotted;
    color: inherit;

    .timer--aside & {
      font-size: 18px;
    }
  }
}

.row-timer-section {
  display: flex;
  position: absolute;
  right: 0.5rem;
  top: 100%;
}
