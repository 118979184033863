&__comment {
  @extend %clearfix;
  position: relative;
  display: flex;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;
  @include media ('>=desktop') {
    margin: 0 -1.9rem 0 -1.3rem;
    padding: 0;
  }

  &:hover {
    &,
    .comment-remove {
      @include media ('>=desktop') {
        background: darken(map-get($colors, gray400), 10%);
        visibility: visible;
      }
    }

    .field-edit {
      opacity: 1;
      visibility: visible;
    }
  }

  &:last-child {
    border-bottom: none;
    @include media ('>=desktop') {
      margin-bottom: 0;
    }
  }

  &.active {
    background: darken(map-get($colors, gray400), 10%);
    visibility: visible;

    .comment-remove {
      background: darken(map-get($colors, gray400), 10%);
      visibility: visible;
    }

    .comment__row--static {
      display: none;
    }

    .comment__row--edit {
      display: flex;
    }
  }

  .comment__row {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    word-break: break-all;
    @include media ('>=desktop') {
      flex-direction: row;
      align-items: center;
      height: 4rem;
      padding-left: 1.2rem;
      padding-right: 1.2rem;
    }

    &.comment__row--edit {
      // @include media ('>=desktop') {
      //   padding-right: 1.8rem;
      // }
      textarea {
        height: 3.2rem;
      }
    }
  }

  time {
    font-weight: 700;
    font-style: italic;
    margin-right: 1rem;
  }

  .comment-remove {
    color: map-get($colors, gray1800);
    margin-right: 1rem;
    line-height: 1;

    @include media ('>=desktop') {
      position: absolute;
      top: 0;
      bottom: 0;
      left: -3rem;
      width: 3.7rem;
      display: flex;
      align-items: center;
      background: map-deep-get($colors, gray400);
      border-radius: 100px 0 0 100px;
      padding: 0 0.5rem;
      visibility: hidden;
    }

    &:hover {
      @include media ('>=desktop') {
        color: map-get($colors, red);
      }
    }
  }

  .book__btn {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    color: #161618;
    background: none;
    border: none;
    @include media ('>=desktop') {
      position: relative;
      transition: opacity 0.3s ease-in-out;
      display: block;
      width: 4rem;
      height: 4rem;
      background: #d6d4d9;
      border: none;
      margin-left: auto;
    }

    .material-icons {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  .field-edit {
    @include media ('>=desktop') {
      opacity: 0;
      visibility: hidden;
    }
  }

  .field-wrapper {
    width: 100%;
  }

  .field-save {
    display: block;
    line-height: 1;

    &__btn--cancel,
    &__btn--save {
      position: relative;

      .material-icons {
        @include absoluteCenter;
        font-size: 1.5rem;
      }
    }

    &__btn--save {
      color: #197c40;
    }

    &__btn--cancel {
      color: #af281f;
    }
  }

  .comment__row--static {
    @include media ('>=desktop') {
      display: flex;
    }
  }

  .comment-text-static {
    padding-right: 30px;
    @include media ('>=desktop') {
      padding-right: 0;
    }
  }

  .comment__row--edit {
    display: none;
  }
}
