&__remove {
    @include v-align;
    @include animate (color);
    color: map-deep-get($userBoard, body, removeColor);

    &:active,
    &:hover,
    &:focus {
        color: map-deep-get($userBoard, body, removeActiveColor);
        text-decoration: none;
    }
}
