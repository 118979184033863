// Global variables
//
// Base font styles for headings and links.

$colors: (
  white: #fff,
  black: #000,
  green: #3b964c,

  red: #e24530,
  red100: #e14939,

  orange: #e24530,
  orange100: #f0ad4e,

  yellow: #fcefa1,
  yellow100: #fbf9ee,
  yellow200: #efae57,


  gray100: #f7f5fa,
  gray200: #444,
  gray300: #777,
  gray400: #e3e1e6,
  gray500: #666,
  gray600: #d6d4d9,
  gray700: #e3e1e6,
  gray800: #a0a4aa,
  gray900: #f3f3f3,
  gray1000: #ccc,
  gray1100: #dadada,
  gray1200: #999,
  gray1300: #aaa,
  gray1400: #ccc,
  gray1500: #d3d3d3,
  gray1600: #e6e6e6,
  gray1700: #555,
  gray1800: #a29ea9,
  gray1900: #333,
  gray2000: #ddd,
  gray2100: #f0f3f8,

  black100: #161618,

  blue100: #006fb4,
  blue200: #8f9eb7,

  green100: #158c51
);

$radius: (
  default: 0.5rem
);

// For headers
$headings: (
  'sizes': (
    'h1-font-size': 30px,
    'h2-font-size': 2.4rem,
    'h3-font-size': 1.6rem,
    'h4-font-size': 21px,
    'h5-font-size': 2.4rem,
    'h6-font-size': 2rem
  ),
  'fonts': (
    'headings-font-family': inherit,
    'headings-color': inherit
  )
);

// Global vars for body typography
$base: (
  'base-font-sans-serif': unquote("Arial, 'Helvetica Neue', Helvetica, sans-serif"),
  'base-font-serif': unquote("'Times New Roman', Times, Baskerville, Georgia, serif"),
  'base-text-color': map-get($colors, black),
  'base-background-color': map-get($colors, white),
  'base-link-color':#39f,
  'base-font-size': 1.4rem,
  'base-line-height': 1.2,
  'base-font-family': unquote("Arial, 'Helvetica Neue', Helvetica, sans-serif"),
  'vertical-rhythm': '(map-get($base, base-line-height) * 1em)',
  'base-min-width': 320px
);

// Vars for forms
$forms: (
  'form-element-padding': unquote("0.4em 0.7em"),
  'form-element-border-color': #999,
  'form-element-focus-border-color': #000,
  'placeholder-color': #999
);

// Breakpoints for include media mixin
$breakpoints: (
  'phone': 480px,
  'tablet': 768px,
  'desktop': 980px,
  'lg-desktop': 1200px,
  'widescreen': 1440px
);

// Keywords for include media mixin
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);
