@font-face {
  font-family: 'icomoon';
  src: url('../fonts/iconmoon/icomoon.eot?l4isxd');
  src: url('../fonts/iconmoon/icomoon.eot?l4isxd#iefix') format('embedded-opentype'), url('../fonts/iconmoon/icomoon.ttf?l4isxd') format('truetype'), url('../fonts/iconmoon/icomoon.woff?l4isxd') format('woff'), url('../fonts/iconmoon/icomoon.svg?l4isxd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class*=" icon-"],
[class^="icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_drop_up:before {
  content: "\e900";
}

.icon-arrow_drop_down:before {
  content: "\e901";
}

.icon-close:before {
  content: "\e902";
}

.icon-cancel:before {
  content: "\e903";
}

.icon-plus:before {
  content: "\e904";
}

.icon-pencil:before {
  content: "\e905";
}

.icon-chevron-right:before {
  content: "\e906";
}

.icon-chevron-left:before {
  content: "\e907";
}

.icon-chevron-down:before {
  content: "\e908";
}

.icon-chevron-up:before {
  content: "\e909";
}
