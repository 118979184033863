.datepicker {
    &-holder {
        margin-bottom: 1.5rem;
        @include media ('>=desktop') {
            display: flex;
            align-items: flex-end;
            margin-bottom: 0;
        }

        .row {
            @include media ('>=desktop') {
                width: 100%;
                margin-left: -0.8rem;
                margin-right: -0.8rem;
            }
        }
    }

    &-clear {
        @include animate (color);
        color: map-deep-get($datepicker, clearColor);
        line-height: 1;
        margin-left: 1rem;
        @include media ('>=desktop') {
            position: absolute;
            top: 0;
            left: 94%;
            margin-left: 0;
        }

        &:hover {
            color: map-deep-get($datepicker, clearHoverColor);
        }
    }

    &-field {
        @include media ('>=tablet') {
            width: 44%;
        }
    }

    &-text {
        display: inline-block;
        color: map-deep-get($datepicker, textColor);
        margin-top: 1rem;
        margin-bottom: 1rem;
        @include media ('>=tablet') {
            margin-top: 2rem;
            margin-left: -0.5rem;
            margin-right: 0.5rem;
        }
    }
}
