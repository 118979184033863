$formControl: (
  fontSize: 1.4rem,
  borderColor: map-get($colors, gray1000),
  placeholderColor: map-get($colors, gray300),
  color: map-get($colors, black)
);

$controlLabel: (
  fontSize: 1.3rem,
  fontWeight: 400,
  color: map-get($colors, black100)
)
