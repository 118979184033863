.user-board {
    @extend %clearfix;
    @import 'user-board__header';
    @import 'user-board__body';
    @import 'user-board__row';
    @import 'user-board__column';
    @import 'user-board__edit';
    @import 'user-board__reply';
    @import 'user-board__add';
    @import 'user-board__cancel';
    @import 'user-board__remove';

    .table-responsive {
      @include media ('>=desktop') {
        overflow-x: visible;
      }
    }
}

.user-board__column .help-block {
    z-index: 101 !important;
}
