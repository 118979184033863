&__add {
    display: flex;
    align-items: center;
    color: map-deep-get($userBoard, body, addColor);
    padding: 0.7rem 1.5rem 1.2rem;

    &,
    &:active,
    &:focus,
    &:hover {
        text-decoration: none;
    }

    .material-icons {
        font-size: 1.4rem;
        margin-right: 0.7rem;
    }
}
