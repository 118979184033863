.navbar {
  border-radius: 0;
  border: none;
  margin: 0;
  z-index: 101;

  &-header {
    @include media ('>=tablet') {
      width: 100%;
    }
    @include media ('>=widescreen') {
      width: auto;
    }
  }

  &-toggle {
    @include media ('>=tablet') {
      display: block;
    }
    @include media ('>=widescreen') {
      display: none;
    }
  }

  &-brand {
    padding: 10px;
  }

  &-right {
    @include media ('>=tablet') {
      float: left;
      width: 100%;
      margin: 0;
      text-align: center;
    }
    @include media ('>=widescreen') {
      float: right;
      width: auto;
      margin-right: -1.5rem;
      text-align: left;
    }
  }

  &-text {
    @include media ('>=tablet') {
      float: none;
    }
    @include media ('>=widescreen') {
      float: left;
    }
  }

  &-inverse {
    .navbar-toggle {
      &:hover,
      &:focus {
        background: none;
      }
    }

    .navbar-nav {
      &:not(.navbar-right) {
        > li {
          > a {
            text-align: center;
            @include media ('>widescreen') {
              min-width: 11.2rem;
            }
          }
        }
      }

      > li {
        border-left: 0.1rem solid map-deep-get($navbar, inverse, borderColor);

        &:last-child {
          border-right: 0.1rem solid map-deep-get($navbar, inverse, borderColor);
        }

        &.active {
          > a {
            background: map-deep-get($navbar, inverse, activeBg);
            color: map-deep-get($navbar, inverse, activeColor);
          }
        }
      }
    }
  }

  > .container,
  > .container-fluid {
    .navbar-brand {
      @include media ('>tablet') {
        margin-left: 0;
        padding-top: 1rem;
      }
    }
  }

  &-collapse {
    @include media ('>=tablet') {
      padding-left: 0;
      padding-right: 0;
    }
  }

  &-collapse.collapse {
    @include media ('>=tablet') {
      display: none !important;
    }
    @include media ('>=widescreen') {
      display: block !important;
    }

    &.in {
      @include media ('>=tablet') {
        display: block !important;
      }
    }
  }

  &-nav {
    text-align: center;
    @include media ('>=tablet') {
      width: 100%;
    }
    @include media ('>=widescreen') {
      width: auto;
      float: left;
      text-align: left;
    }

    > li {
      @include media ('>=tablet') {
        width: 100%;
      }
      @include media ('>=widescreen') {
        width: auto;
        float: left;
      }
    }
  }
}

.container-fluid > .navbar-collapse {
  @include media ('>=tablet') {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  @include media ('>=widescreen') {
    margin-left: 0;
    margin-right: 0;
  }
}
