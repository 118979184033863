.user-popup {
    top: 5rem;
    left: 0;
    right: 0;
    box-shadow: 0 0 1rem 0 map-get($userPopup, shadowColor);
    padding: 1.7rem 1.9rem 1.3rem;
    border-radius: 0.6rem;
    background: map-deep-get($userPopup, background);
    z-index: 100;
    width: calc(100% - 1rem);
    margin: 0 0.5rem;

    @include media ('>=desktop') {
        position: absolute;
        margin: 0;
    }

    .user-board & {
        left: 2rem;
    }

    .user-board {
        height: 24rem;
        overflow: auto;
    }

    .user-board__column {
        padding: 1rem 1rem 1rem 0;
    }

    @import 'user-popup__arrow';
    @import 'user-popup__header';
}
