&__header {
    @extend %clearfix;
    position: relative;

    .close {
        position: absolute;
        top: -1rem;
        right: -0.5rem;
        z-index: 11;
    }
}
