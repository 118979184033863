.modal {
    text-align: center;
    /*
    * Modal vertical align CSS realization
    */
    &:before {
        @include media ('>=desktop') {
          content: "";
          display: inline-block;
          height: 100%;
          margin-right: -0.4rem;
          vertical-align: middle;
        }
    }
    @import 'modal-header';
    @import 'modal-body';
    @import 'modal-footer';
    @import 'modal-dialog';
    @import 'modal-content';
    @import 'modal-title';
    @import 'modal-close';
}

.modal-lg {
  @include media ('>=desktop') {
    width: 73rem;
  }
}
