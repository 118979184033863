&__edit {
    @include animate (opacity);
    @include v-align;
    display: block;
    width: 100%;
    height: 4rem;
    @include media ('>=desktop') {
      opacity: 0;
      visibility: hidden;
    }

    .user-board__row--edit &,
    .user-board__row:hover & {
      @include media ('>=desktop') {
        opacity: 1;
        visibility: visible;
      }
    }

    &,
    &:active,
    &:focus,
    &:hover {
        background: map-deep-get($userBoard, body, editBg);
        color: map-deep-get($userBoard, body, editColor);
    }

    &--active {
        background: map-deep-get($userBoard, body, editBg);

        &:active,
        &:focus,
        &:hover {
            background: map-deep-get($userBoard, body, activeEditBg);
            color: map-deep-get($userBoard, body, activeEditColor);
        }
    }
}
