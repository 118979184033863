&__heading {
  padding: 1rem;
  background: map-deep-get($book, heading, background);
  border-radius: 0.5rem 0.5rem 0 0;
  text-align: center;

  @include media ('>=tablet') {
    display: flex;
    align-items: center;
    padding: 1rem 1.8rem 0.7rem;
    text-align: left;
  }

  .dropdown-menu {
    width: 100%;
    left: 0;
    right: 0;
  }
}
