.panel {
  background-color: transparent;
  border-radius: 0;
  box-shadow: none;

  .book & {
    margin: 0;
    background: none;
  }

  &-heading {
    display: flex;
    align-items: center;
    cursor: pointer;

    > * {
      margin-right: 0.8rem;
    }

    .book & {
      padding: 0 0 1rem;
    }
  }

  &-body {
    border-top: 0.1rem solid map-deep-get($panel, body, borderColor);

    .book & {
      padding: 0;
    }
  }
}
