.discount {
  @extend %clearfix;
  margin-left: -.25rem;
  margin-right: -.25rem;

  .book__summary & {
    margin: 0 -0.4rem 0 -0.25rem;
  }

  [class^='col-'] {
    padding: 0 0.25rem;
  }

  .bootstrap-select .btn {
    font-size: 1.3rem;
    font-weight: 400;
  }

  .form-control {
    padding: 0.25rem 0.5rem;
  }

  &-row-edit,
  &-row-text {
    @extend %clearfix;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;

    @include media ('>=desktop') {
      height: 3.7rem;
    }
  }

  .discount-row-edit,
  .discount-row-text {
    margin: 0 0 1rem;

    @include media ('>=desktop') {
      margin: 0;
    }
  }

  &-row-edit,
  &-row-new,
  &-row-text:hover {
    @include media ('>=desktop') {
      background: map-deep-get($discount, editBg);
    }

    .bootstrap-select {
      margin-top: 0.3rem;
    }

    .dropdown-menu {
      width: 100%;
      margin-top: -0.1rem;

      > li {
        > a {
          font-size: 1.3rem;
          line-height: 1.2;
          padding: 0.6rem 0.8rem;
          color: map-get($colors, black100);
          margin: 0;
        }
      }
    }
  }

  .btn {
    @include v-align;
    width: 2.6rem;
    height: 2.6rem;
    padding: 0;
    float: left;

    @supports (display: flex) {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &.dropdown-toggle {
      width: 100%;
      padding-left: 0.5rem;
      padding-right: 0.5rem;
    }

    .material-icons {
      font-size: 1.8rem;
    }
  }

  .remove-real-discount {
    @include media ('>=desktop') {
      position: absolute;
      top: 0;
      bottom: 0;
      border-radius: 10rem 0 0 10rem;
      right: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: map-deep-get($discount, editBg);
      width: 3rem;
    }

    &:hover {
      .material-icons {
        color: map-deep-get($discount, activeRemoveRealColor);
      }
    }

    .material-icons {
      font-size: 2rem;
      color: map-deep-get($discount, removeRealColor);
    }
  }

  &__btn-box {
    display: flex;
    justify-content: flex-end;
    margin: 0 -0.25rem;
  }

  .discount__remove,
  .discount__save {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 0.4rem;
    background: map-deep-get($discount, buttonsBg);
    color: map-deep-get($discount, buttonsColor);
    border-radius: 0;
  }

  &__save {
    margin-left: 0.2rem;

    &:hover {
      background: map-get($colors, green);
      color: map-get($colors, white);
    }
  }

  &__remove {
    &:hover {
      background: map-get($colors, red);
      color: map-get($colors, white);
    }

    .material-icons {
      font-size: 1.7rem;
    }
  }

  .dropdown-toggle,
  .form-control {
    background: map-get($colors, white);
    border: 0.1rem solid map-get($colors, gray1000);
    border-radius: 0.4rem;
    height: 2.8rem;
  }
}

.discount .has-error .help-block {
  opacity: 1;
  visibility: visible;
}

.discount .help-block {
  position: absolute;
  margin: 0;
  background: rgba(22, 22, 24, 0.8);
  color: #fff;
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  min-width: 20rem;
  opacity: 0;
  visibility: hidden;
  z-index: 100;
  margin: 1rem 0 0;
}

.discount .help-block:after {
  position: absolute;
  bottom: 100%;
  left: 2rem;
  content: '';
  height: 0;
  width: 0;
  border-left: 0.6rem solid transparent;
  border-right: 0.6rem solid transparent;
  border-bottom: 0.6rem solid rgba(22, 22, 24, 0.8);
}
