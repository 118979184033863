$datepicker: (
  borderColor: map-get($colors, gray1300),
  borderRadius: .4rem,

  range: (
    color: map-get($colors, black),
    background: map-get($colors, white),
    borderColor: map-get($colors, white),
    activeBorderColor: map-get($colors, gray1200),
    activeBackground: unquote("linear-gradient(to bottom, #e8e9e8 0%,#e8e9e8 46%,#e0e0e0 52%,#e0e0e0 57%,#eaeaea 100%)"),
  ),

  calendar: (
    background: map-get($colors, gray1400),
    borderColor: map-get($colors, gray1200),
  ),

  calendarCell: (
    background: unquote("linear-gradient(to bottom, #e8e9e8 0%,#e8e9e8 46%,#e0e0e0 52%,#e0e0e0 57%,#eaeaea 100%)"),
    borderColor: map-get($colors, gray1500),
    color: map-get($colors, gray1700),
    inRangeBg: unquote("linear-gradient(to bottom, #CACBCA 0%,#CACBC6 46%,#C6C7C3 52%,#BCBCBC 57%,#B2B2B2 100%)"),
    inRangeBorder: darken(map-get($colors, gray1500), 10%),
    todayBorder: map-get($colors, yellow),
    todayBg: map-get($colors, yellow100),
    activeBorder: map-get($colors, gray1300),
    activeBg: map-get($colors, white),
    hoverBorder: map-get($colors, gray1200)
  )
);
