&__close {
  @include animate (color);
  position: absolute;
  top: -3.3rem;
  right: 1rem;
  font-size: 2.2rem;
  color: map-deep-get($schedule, close, color);
  margin-left: 0.5rem;

  &:hover {
    color: map-deep-get($schedule, close, hoverColor);
  }

  &:focus {
    color: map-deep-get($schedule, close, color);

    &:hover {
      color: map-deep-get($schedule, close, hoverColor);
    }
  }

  @include media ('>=desktop') {
      top: -0.3rem;
      left: 100%;
      right: auto;
  }
}
